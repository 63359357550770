import React, { useState, useEffect } from "react";
import axios from "axios";
import "../Styles/TransactionList.css";

function TransactionList() {
  const [transactions, setTransactions] = useState([]); // Store fetched transactions
  const [transactionsToShow, setTransactionsToShow] = useState(10); // Items per page
  const [currentPage, setCurrentPage] = useState(1); // For pagination
  const [filterType, setFilterType] = useState("All"); // Filter type
  const [dateRange, setDateRange] = useState({ from: "", to: "" }); // Date range for filtering
  const [loading, setLoading] = useState(false); // For loading indicator

  // Fetch transaction data from the API
  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token"); // Assuming token is stored
        const response = await axios.get(
          "https://api.ijeaweleinvest.com/wallet/transactions/",
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setTransactions(response.data); // Store fetched transactions
      } catch (error) {
        console.error("Error fetching transactions:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchTransactions();
  }, []);

  // Apply filters and pagination
  const filteredTransactions = transactions
    .filter((transaction) => {
      // Filter based on transaction type (if not 'All')
      return filterType === "All" || transaction.type === filterType;
    })
    .filter((transaction) => {
      // Filter based on date range
      const transactionDate = new Date(transaction.date);
      const from = new Date(dateRange.from);
      const to = new Date(dateRange.to);
      return (
        (!dateRange.from || transactionDate >= from) &&
        (!dateRange.to || transactionDate <= to)
      );
    });

  const paginatedTransactions = filteredTransactions.slice(
    (currentPage - 1) * transactionsToShow,
    currentPage * transactionsToShow
  );

  // Event handlers
  const handleFilterChange = (e) => setFilterType(e.target.value);
  const handleDateRangeChange = (e, type) => {
    setDateRange((prev) => ({ ...prev, [type]: e.target.value }));
  };

  const handleNextPage = () => {
    if (
      currentPage < Math.ceil(filteredTransactions.length / transactionsToShow)
    ) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  return (
    <div className="transaction-list-container">
      <h1>Transactions</h1>

      {/* Filters */}
      <div className="filters">
        <select
          className="filter-button"
          value={filterType}
          onChange={handleFilterChange}
        >
          <option className="one" value="All">
            All
          </option>
          <option value="Deposit">Deposit</option>
          <option value="Withdrawal">Withdrawal</option>
        </select>
        <div className="date-filters">
          <input
            type="date"
            className="select-from"
            value={dateRange.from}
            onChange={(e) => handleDateRangeChange(e, "from")}
          />
          <span className="to">to</span>
          <input
            type="date"
            value={dateRange.to}
            onChange={(e) => handleDateRangeChange(e, "to")}
          />
        </div>
      </div>

      {/* Loading state */}
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          {/* Transaction table */}
          <table className="transaction-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Type</th>
                {/* <th>Bank Name</th> */}
                <th>Status</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {paginatedTransactions.map(
                ({
                  id,
                  created_at,
                  transaction_type,
                  bank,
                  status,
                  amount,
                }) => (
                  <tr
                    key={id}
                    className={
                      status === "Successful" ? "successful" : "ongoing"
                    }
                  >
                    <td>
                      {created_at
                        ? new Date(Date.parse(created_at)).toLocaleDateString()
                        : "No Date"}
                    </td>
                    <td>{transaction_type || "N/A"}</td>
                    {/* <td>{bank}</td> */}
                    <td>{status}</td>
                    <td>{amount}</td>
                  </tr>
                )
              )}
            </tbody>
          </table>

          {/* Show more / pagination controls */}
          <div className="pagination-controls">
            <label>Show</label>
            <select
              value={transactionsToShow}
              onChange={(e) => setTransactionsToShow(Number(e.target.value))}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </select>
            <button
              onClick={handlePrevPage}
              className="disabled-btn"
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <button
              onClick={handleNextPage}
              disabled={
                currentPage ===
                Math.ceil(filteredTransactions.length / transactionsToShow)
              }
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default TransactionList;
