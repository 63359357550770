import { useState, useEffect } from "react";
import axios from "axios";

function ManualDeposit({ companyBankDetails, setDepositOption }) {
  const [depositAmount, setDepositAmount] = useState("");
  const [receiptImage, setReceiptImage] = useState(null);
  const [senderName, setSenderName] = useState(""); // New state for sender name
  const [userId, setUserId] = useState(null);
  const [message, setMessage] = useState(null); // For success/error messages
  const [messageType, setMessageType] = useState(""); // "success" or "error"
  const [fileName, setFileName] = useState(""); // For file name display
  const [loading, setLoading] = useState(false); // Loader state
  const [bankDetails, setBankDetails] = useState(null); // Bank details state

  // Fetch the current user's details
  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          "https://api.ijeaweleinvest.com/users/current_user/",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          setUserId(response.data.user);
        }
      } catch (error) {
        showMessage("Failed to fetch user details. Please try again.", "error");
      }
    };

    fetchCurrentUser();
  }, []);

  // Fetch bank details
  useEffect(() => {
    const fetchBankDetails = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          "https://api.ijeaweleinvest.com/wallet/bank_account_details/",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200 && response.data.length > 0) {
          setBankDetails(response.data[0]); // Use the first bank account in the list
        }
      } catch (error) {
        console.error("Failed to fetch bank details:", error);
      }
    };

    fetchBankDetails();
  }, []);

  // Handle file input change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setReceiptImage(file);
      setFileName(file.name); // Set the file name for display
    }
  };

  // Show messages in UI
  const showMessage = (text, type) => {
    setMessage(text);
    setMessageType(type);
    setTimeout(() => {
      setMessage(null);
      setMessageType("");
    }, 5000); // Clear message after 5 seconds
  };

  const handleManualDepositSubmit = async () => {
    if (!receiptImage || !depositAmount || !senderName) {
      showMessage("Please upload a receipt, enter the amount, and provide the sender's name.", "error");
      return;
    }

    if (!userId) {
      showMessage("User details not found. Please try again.", "error");
      return;
    }

    const formData = new FormData();
    formData.append("receipt_image", receiptImage);
    formData.append("amount", depositAmount);
    formData.append("user", userId);
    formData.append("sender_name", senderName); // Add sender name to payload

    setLoading(true); // Show loader

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        "https://api.ijeaweleinvest.com/wallet/receipts/upload/",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 201) {
        showMessage("Receipt uploaded successfully. Awaiting confirmation.", "success");

        // Delay closing the modal to show the success message
        setTimeout(() => {
          setDepositOption(null);
        }, 3000); // Close after 3 seconds
      }
    } catch (error) {
      console.error("Error uploading receipt:", error);
      showMessage("Failed to upload receipt. Please try again.", "error");
    } finally {
      setLoading(false); // Hide loader
    }
  };

  return (
    <div className="overlay">
      <div className="deposit-popup">
        <h3>Deposit</h3>
        <p className="manual-deposit-instruction">
          <span className="note">Note:</span> After depositing to the account
          below, upload your receipt and enter the deposited amount in the
          fields provided for confirmation.
        </p>
        <p>
          Account Name:{" "}
          {/* <span className="account-name"> */}
          <span className="account-number">
            {bankDetails?.account_name || companyBankDetails.accountName}
          </span>
        </p>
        <p>
          Account Number:{" "}
          <span className="account-number">
            {bankDetails?.account_number || companyBankDetails.accountNumber}
          </span>
        </p>
        <p className="bank-name-p">
          Bank Name:{" "}
          <span className="bank-name">
            {bankDetails?.bank_name || companyBankDetails.bankName}
          </span>
        </p>

        <input
          type="text"
          placeholder="Enter sender's name"
          value={senderName}
          onChange={(e) => setSenderName(e.target.value)}
          className="deposit-input"
        />

        <input
          type="text"
          placeholder="Enter amount deposited"
          value={
            depositAmount !== ""
              ? `₦${Number(depositAmount).toLocaleString("en-NG")}`
              : ""
          }
          onChange={(e) => {
            const value = e.target.value.replace(/₦|,/g, "");
            if (!isNaN(value) && value >= 0) {
              setDepositAmount(value);
            }
          }}
          onFocus={(e) => {
            if (e.target.value === "") {
              setDepositAmount("");
            }
          }}
          className="deposit-input"
        />

        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
        />
        {fileName && <p className="file-name">Selected File: {fileName}</p>}

        <button
          onClick={handleManualDepositSubmit}
          className="confirm-deposit-btn"
          disabled={loading} // Disable button during loading
        >
          {loading ? "Submitting..." : "Submit Receipt"}
        </button>
        <button
          onClick={() => setDepositOption(null)}
          className="cancel-btn"
          disabled={loading} // Disable cancel button during loading
        >
          Cancel
        </button>

        {message && (
          <div className={`message ${messageType}`}>
            {message}
          </div>
        )}
      </div>
    </div>
  );
}

export default ManualDeposit;
