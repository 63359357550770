import { useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import Layout from "../Components/Layout";
import { useAuth } from '../Components/AuthContext';
import { CSSTransition } from "react-transition-group";
import "../Styles/Logout.css";

function Logout() {
  const navigate = useNavigate();
  const [inProp, setInProp] = useState(false);
  const logoutPageRef = useRef(null);
  const { logout } = useAuth();

  useEffect(() => {
    setInProp(true);
  }, []);

  const handleLogout = async () => {
    await logout(navigate);
  };

  const handleCancel = () => {
    navigate("/overview");
  };

  return (
    <Layout>
      <CSSTransition
        in={inProp}
        timeout={1000}
        classNames="fade"
        nodeRef={logoutPageRef}
      >
        <div ref={logoutPageRef} className="logout-page">
          <h1>You are about to log out.</h1>
          <p>Are you sure you want to continue?</p>
          <div className="logout-buttons">
            <button className="logout-button" onClick={handleLogout}>
              Logout
            </button>
            <button className="cancel-button" onClick={handleCancel}>
              Cancel
            </button>
          </div>
        </div>
      </CSSTransition>
    </Layout>
  );
}

export default Logout;

