import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TextInput from "../Components/TextInput";
import PasswordInput from "../Components/PasswordInput";
import axios from "axios";
import Logo from "../Assets/logo.svg";
import { RevolvingDot } from "react-loader-spinner";
import "../Styles/SignUp.css";

function SignUp() {
  const [isAnimated, setIsAnimated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // Global error message

  const [userName, setUserName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [referralCode, setReferralCode] = useState("");

  const [errorUserName, setErrorUserName] = useState("");
  const [errorPhoneNumber, setErrorPhoneNumber] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");

  const [userNameColor, setUserNameColor] = useState("");
  const [phoneNumberColor, setPhoneNumberColor] = useState("");
  const [emailColor, setEmailColor] = useState("");
  const [passwordColor, setPasswordColor] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    setIsAnimated(true);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/overview");
    }
  }, [navigate]);

  // Form validation
  const validate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage("");

    let isValid = true;

    // Name Validation
    if (
      /^[a-zA-Z]+(?:[-' ][a-zA-Z]+)*$/.test(userName) &&
      userName.trim().split(" ").length >= 2
    ) {
      setErrorUserName("");
      setUserNameColor("green");
    } else {
      setErrorUserName("Enter your full name (first and last name).");
      setUserNameColor("red");
      isValid = false;
    }

    // Phone Number Validation
    if (phoneNumber.length > 10 && !phoneNumber.includes("+/-")) {
      setErrorPhoneNumber("");
      setPhoneNumberColor("green");
    } else {
      setErrorPhoneNumber("Invalid phone number format.");
      setPhoneNumberColor("red");
      isValid = false;
    }

    // Email Validation
    // const emailRegex = /^\w+@\w+\.\w+$/;
    const emailRegex = /^[\w.-]+@\w+(\.\w+)+$/;
    if (emailRegex.test(email)) {
      setErrorEmail("");
      setEmailColor("green");
    } else {
      setErrorEmail("Invalid email format.");
      setEmailColor("red");
      isValid = false;
    }

    // Password Validation
    if (
      password.length >= 8 &&
      /[a-z]/.test(password) &&
      /[A-Z]/.test(password) &&
      /[0-9]/.test(password) &&
      /[^a-zA-Z0-9]/.test(password)
    ) {
      setErrorPassword("");
      setPasswordColor("green");
    } else {
      setErrorPassword(
        "Password must be at least 8 characters, including lowercase, uppercase, number, and special character."
      );
      setPasswordColor("red");
      isValid = false;
    }

    // STOP if any field is invalid
    if (!isValid) {
      setIsLoading(false);
      return;
    }

    // Prepare registration data
    const fullName = userName.trim().split(" ");
    const firstName = fullName[0];
    const lastName = fullName.slice(1).join(" ");

    const registrationData = {
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      email: email.toLowerCase(),
      password: password,
      referral_link: referralCode
        ? `https://ijeaweleinvest.com/signup?referral_code=${referralCode}`
        : undefined,
    };

    // Send registration request
    try {
      const response = await axios.post(
        "https://api.ijeaweleinvest.com/users/register/",
        registrationData
      );
      setIsLoading(false);

      if (response.status === 201) {
        localStorage.setItem("email", email.toLowerCase());
        navigate("/otpverification", { state: { email: email } });
      }
    } catch (error) {
      setIsLoading(false);

      if (error.response) {
        const serverError = error.response.data;
        if (serverError.email || error.response.status === 500) {
          setErrorEmail("Email or phone number already in use.");
        } else if (serverError.phone_number || error.response.status === 500) {
          setErrorPhoneNumber("Invalid or already used phone number.");
        } else {
          setErrorMessage("Registration failed. Please try again.");
        }
      } else if (error.request) {
        setErrorMessage("Network error. Check your connection.");
      } else {
        setErrorMessage("An unexpected error occurred. Please try again.");
      }
    }
  };

  return (
    <div className="signup-container no-topnav-page">
      <div className={`left-content ${isAnimated ? "slide-in-left" : ""}`}>
        <img className="centered-logo" src={Logo} alt="Ijeawele logo" />
        <h1 className="hello-text">Hello</h1>
        <h2 className="welcome-text">Create An Account With Ijeawele</h2>
      </div>
      <div className={`right-form ${isAnimated ? "slide-in-right" : ""}`}>
        <div className="form-card">
          <h2>Sign Up</h2>
          <p>Welcome to Ijeawele Investments</p>
          <form onSubmit={validate}>
            <TextInput
              label="Full Name"
              type="text"
              id="fullName"
              placeholder="Enter your first and last name"
              style={{ borderColor: userNameColor }}
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              error={errorUserName}
            />
            <TextInput
              label="Phone Number"
              type="tel"
              id="phoneNumber"
              placeholder="Enter your phone number"
              style={{ borderColor: phoneNumberColor }}
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              error={errorPhoneNumber}
            />
            <TextInput
              label="Email"
              type="email"
              id="email"
              placeholder="Enter your email"
              style={{ borderColor: emailColor }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={errorEmail}
            />
            <PasswordInput
              id="password"
              placeholder="Enter your password"
              label="Password"
              style={{ borderColor: passwordColor }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={errorPassword}
            />
            <TextInput
              label="Referral Code (Optional)"
              type="text"
              id="referralCode"
              placeholder="Enter referral code (if any)"
              value={referralCode}
              onChange={(e) => setReferralCode(e.target.value)}
            />

            {isLoading && (
              <RevolvingDot color="#00BFFF" height={40} width={40} />
            )}
            {errorMessage && <p className="error-message">{errorMessage}</p>}

            <button type="submit" disabled={isLoading}>
              Sign Up
            </button>
          </form>

          <p className="create-account-text">
            Already have an Account? <Link to="/signin">Sign In Here!</Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
