import React, { useState } from "react";
import TextInput from "../Components/TextInput";
import AddressInput from "./AddressInput";
import VerificationStatus from "./VerificationStatus";
import PinCreation from "./PinCreation";
import ProfilePicture from "./ProfilePicture";
import "../Styles/PersonalInfo.css";
import Referral from "./Referral";
import { useAuth } from "../Components/AuthContext"; // Import the Auth context

function PersonalInfo() {
  const { user } = useAuth(); // Get the current user from AuthContext

  const [firstName, setFirstName] = useState(user?.first_name || "");
  const [lastName, setLastName] = useState(user?.last_name || "");
  const [email, setEmail] = useState(user?.email || "");
  const [address, setAddress] = useState(user?.address || "");

  return (
    <div className="profile-content">
      <h4 className="personal-info">Personal Information</h4>
      {/* Profile image */}
      <ProfilePicture userId={user?.id} />

      {/* Input Fields */}
      <div className="profile-inputs">
        <TextInput
          label="First Name"
          id="firstName"
          type="text"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextInput
          label="Last Name"
          id="lastName"
          type="text"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <TextInput
          label="Email"
          id="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <AddressInput
          address={address}
          userId={user?.id}
          setAddress={setAddress}
        />
      </div>

      {/* PIN creation */}
      <PinCreation />

      {/* Referral link */}
      <Referral />

      {/* Verification status and withdrawal button */}
      <VerificationStatus />
    </div>
  );
}

export default PersonalInfo;
