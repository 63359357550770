import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../Styles/Header.css";
import ProfileImagePlaceholder from "../Assets/picture-profile-icon.jpg";
import Logo from "../Assets/logo-3.svg";
import { IoMdMenu } from "react-icons/io";

function Header({ onToggleSidebar, updatedProfileImage }) {
  const [profileImage, setProfileImage] = useState(localStorage.getItem("profileImage") || ProfileImagePlaceholder);
  const [profileName, setProfileName] = useState("user");

  useEffect(() => {
    const fetchUserDetails = async () => {
      const token = localStorage.getItem("token");
      if (!token) return;

      try {
        const response = await fetch("https://api.ijeaweleinvest.com/users/current_user", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const data = await response.json();
          const imageUrl = data.profile_image ? `https://api.ijeaweleinvest.com${data.profile_image}` : ProfileImagePlaceholder;
          setProfileImage(imageUrl);
          setProfileName(`${data.first_name} ${data.last_name}`);
          if (data.profile_image) {
            localStorage.setItem("profileImage", imageUrl);
          }
        } else {
          console.error("Failed to fetch user data.");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };
    fetchUserDetails();
  }, []);

  useEffect(() => {
    if (updatedProfileImage) {
      setProfileImage(updatedProfileImage);
      localStorage.setItem("profileImage", updatedProfileImage);
    }
  }, [updatedProfileImage]);

  return (
    <header className="header">
      <div className="header-logo">
        <img src={Logo} alt="Ijeawele Logo" />
      </div>
      <div className="right-side">
        <div className="header-right">
          <Link to="/profile" className="profile-link">
            <img src={profileImage} alt="Profile" className="profile-image" />
            <span className="profile-greeting">
              Hello, <span className="profileName">{profileName}</span>
            </span>
          </Link>
        </div>
        <div className="menu-icon" onClick={onToggleSidebar}>
          <IoMdMenu size={30} />
        </div>
      </div>
    </header>
  );
}

export default Header;
