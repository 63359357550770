import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; 
import TextInput from "../Components/TextInput";
import PasswordInput from "../Components/PasswordInput";
import axios from "axios";
import Logo from "../Assets/logo.svg";
import { RevolvingDot } from "react-loader-spinner";
import "../Styles/SignUp.css";

function ForgotPassword() {
  const [isAnimated, setIsAnimated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [step, setStep] = useState(1);
  const [message, setMessage] = useState({ text: "", type: "" });

  useEffect(() => {
    setIsAnimated(true);
  }, []);

  const showMessage = (text, type) => {
    setMessage({ text, type });
    setTimeout(() => setMessage({ text: "", type: "" }), 7000);
  };

  const logError = (error, functionName) => {
    console.error(`❌ Error in ${functionName}:`, error);
    console.error("Response Data:", error.response?.data);
    console.error("Status Code:", error.response?.status);
  };

  const handleForgetPassword = async (e) => {
    e.preventDefault();

    const lowerCaseEmail = email.trim().toLowerCase();
    console.log("📩 Sending OTP to:", lowerCaseEmail);

    if (!lowerCaseEmail) {
      showMessage("Email is required", "error");
      return;
    }

    try {
      setIsLoading(true);
      const response = await axios.post("https://api.ijeaweleinvest.com/users/forgot_password/", {
        email: lowerCaseEmail,
      });

      console.log("✅ OTP Sent Successfully:", response.data);
      showMessage("OTP sent to your email. This OTP will expire in 5 minutes.", "success");
      setStep(2);
    } catch (error) {
      logError(error, "handleForgetPassword");

      if (error.response) {
        switch (error.response.status) {
          case 404:
            showMessage("User with this email does not exist.", "error");
            break;
          case 400:
            showMessage("Invalid email format. Please enter a valid email.", "error");
            break;
          case 500:
            showMessage("Server error. Please try again later.", "error");
            break;
          default:
            showMessage(error.response?.data?.message || "An unexpected error occurred.", "error");
        }
      } else {
        showMessage("Network error. Please check your internet connection.", "error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    const lowerCaseEmail = email.trim().toLowerCase();
    console.log("🔑 Resetting password for:", lowerCaseEmail);

    if (!otp || !lowerCaseEmail || !newPassword || !confirmPassword) {
      showMessage("All fields are required", "error");
      return;
    }

    if (newPassword !== confirmPassword) {
      showMessage("Passwords do not match", "error");
      return;
    }

    try {
      setIsLoading(true);
      const response = await axios.post("https://api.ijeaweleinvest.com/users/reset-password/", {
        email: lowerCaseEmail,
        otp,
        new_password: newPassword,
        confirm_password: confirmPassword,
      });

      showMessage("Password reset successfully", "success");

      // Clear input fields on success
      setStep(1);
      setEmail("");
      setOtp("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      logError(error, "handleResetPassword");

      if (error.response) {
        switch (error.response.status) {
          case 400:
            showMessage("Invalid OTP or password format.", "error");
            break;
          case 401:
            showMessage("Unauthorized. OTP may have expired.", "error");
            break;
          case 404:
            showMessage("User with this email does not exist.", "error");
            break;
          case 500:
            showMessage("Server error. Please try again later.", "error");
            break;
          default:
            showMessage(error.response?.data?.message || "An unexpected error occurred.", "error");
        }
      } else {
        showMessage("Network error. Please check your internet connection.", "error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="signup-container no-topnav-page">
      <div className={`left-content ${isAnimated ? "slide-in-left" : ""}`}>
        <img className="centered-logo" src={Logo} alt="Company logo" />
        <h1 className="hello-text">Hello</h1>
        <h2 className="welcome-text">Recover your Account with Ease</h2>
      </div>

      <div className={`right-form ${isAnimated ? "slide-in-right" : ""}`}>
        <div className="form-card">
          <h2>Password Forgotten</h2>
          <p>To reset your password, Please enter the email associated with your Ijeawele Account</p>
          {message.text && (
            <p className={`message ${message.type === "error" ? "error" : "success"}`}>
              {message.text}
            </p>
          )}

          {/* Step 1: Send OTP */}
          {step === 1 && (
            <form onSubmit={handleForgetPassword}>
              <TextInput
                label="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
              />

              {isLoading && <RevolvingDot color="#00BFFF" height={30} width={30} />}
              <button type="submit" disabled={isLoading}>
                {isLoading ? "Sending..." : "Send OTP"}
              </button>
            </form>
          )}

          {/* Step 2: Reset Password */}
          {step === 2 && (
            <form onSubmit={handleResetPassword}>
              <TextInput
                type="text"
                label="OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                placeholder="Enter OTP"
              />
              <TextInput
                type="email"
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
              />
              <PasswordInput
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="New Password"
              />
              <PasswordInput
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm New Password"
              />

              {isLoading && <RevolvingDot color="#00BFFF" height={30} width={30} />}
              <button type="submit" disabled={isLoading}>
                {isLoading ? "Resetting..." : "Reset Password"}
              </button>
            </form>
          )}

          <p className="create-account-text">
            Head back to Sign In? <Link to="/signin">Click Here</Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
