import React, { useState } from "react";
import axios from "axios";
import "../Styles/Security.css";

const PasswordReset = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const showMessage = (text, type) => {
    setMessage({ text, type });
    setTimeout(() => setMessage(null), 7000);
  };

  const logError = (error, functionName) => {
    console.error(`❌ Error in ${functionName}:`, error);
    console.error("Response Data:", error.response?.data);
    console.error("Status Code:", error.response?.status);
  };

  const handleSendOtp = async (e) => {
    e.preventDefault();

    if (!email) {
      showMessage("Email is required", "error");
      return;
    }

    setIsLoading(true);

    try {
      await axios.post("https://api.ijeaweleinvest.com/users/forgot_password/", {
        email: email.toLowerCase(),
      });
      setStep(2);
      showMessage("OTP sent to your email. This OTP will expire in 5 minutes.", "success");
    } catch (error) {
      logError(error, "handleSendOtp");
      if (error.response) {
        switch (error.response.status) {
          case 404:
            showMessage("User with this email does not exist.", "error");
            break;
          case 400:
            showMessage("Invalid email format. Please enter a valid email.", "error");
            break;
          case 500:
            showMessage("Server error. Please try again later.", "error");
            break;
          default:
            showMessage(error.response?.data?.message || "An unexpected error occurred.", "error");
        }
      } else {
        showMessage("Network error. Please check your internet connection.", "error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();

    if (!otp || !newPassword || !confirmNewPassword) {
      showMessage("All fields are required", "error");
      return;
    }

    if (newPassword !== confirmNewPassword) {
      showMessage("Passwords do not match", "error");
      return;
    }

    setIsLoading(true);

    try {
      await axios.post("https://api.ijeaweleinvest.com/users/reset-password/", {
        email: email.toLowerCase(),
        otp,
        new_password: newPassword,
        confirm_password: confirmNewPassword,
      });
      showMessage("Password reset successfully. You can now log in with your new password.", "success");
      setStep(1);
      setEmail("");
      setOtp("");
      setNewPassword("");
      setConfirmNewPassword("");
    } catch (error) {
      logError(error, "handlePasswordReset");
      if (error.response) {
        switch (error.response.status) {
          case 400:
            showMessage("Invalid OTP or password format.", "error");
            break;
          case 401:
            showMessage("Unauthorized. OTP may have expired.", "error");
            break;
          case 404:
            showMessage("User with this email does not exist.", "error");
            break;
          case 500:
            showMessage("Server error. Please try again later.", "error");
            break;
          default:
            showMessage(error.response?.data?.message || "An unexpected error occurred.", "error");
        }
      } else {
        showMessage("Network error. Please check your internet connection.", "error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="accordion-item">
      <button className="accordion-button" onClick={() => setIsExpanded(!isExpanded)}>
        Reset Password
      </button>
      {isExpanded && (
        <>
          {step === 1 ? (
            <form onSubmit={handleSendOtp}>
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button type="submit" disabled={isLoading}>
                {isLoading ? "Sending..." : "Send OTP"}
              </button>
            </form>
          ) : (
            <form onSubmit={handlePasswordReset}>
              <input
                type="text"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
              <input
                type="password"
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <input
                type="password"
                placeholder="Confirm New Password"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
              <button type="submit" disabled={isLoading}>
                {isLoading ? "Resetting..." : "Reset Password"}
              </button>
            </form>
          )}
          {message && (
            <p className={`message ${message.type === "error" ? "error" : "success"}`}>
              {message.text}
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default PasswordReset;