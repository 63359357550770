import { usePaystackPayment } from "react-paystack";

function PaystackDeposit({ userEmail, depositAmount, setDepositOption }) {
  const initializePayment = usePaystackPayment({
    email: userEmail,
    amount: depositAmount * 100,
    publicKey: "pk_live_2af1d277190e7680d0309464e7576bf4213d8464",
  });

  const handlePaystackPayment = () => {
    initializePayment(
      (reference) => {
        console.log("Payment successful", reference);
        setDepositOption(null);
      },
      () => {
        console.log("Payment closed");
      }
    );
  };

  return (
    <div className="overlay">
      <div className="deposit-popup">
        <h3>Proceed with Paystack</h3>
        <p>Amount: ₦{depositAmount.toLocaleString("en-NG")}</p>
        <button onClick={handlePaystackPayment} className="confirm-deposit-btn">
          Proceed to Paystack
        </button>
        <button onClick={() => setDepositOption(null)} className="cancel-btn">
          Cancel
        </button>
      </div>
    </div>
  );
}

export default PaystackDeposit;
