import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../Styles/VerificationStatus.css";

const VerificationStatus = () => {
  const [verificationStatus, setVerificationStatus] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    const fetchVerificationStatus = async () => {
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          console.error("Missing user token in local storage");
          return;
        }

        const response = await fetch(
          "https://api.ijeaweleinvest.com/users/current_user/",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          console.error(
            "Failed to fetch verification status:",
            response.statusText
          );
          return;
        }

        const data = await response.json();
        setVerificationStatus(data.verification_status);
      } catch (error) {
        console.error("Error fetching verification status:", error);
      }
    };

    fetchVerificationStatus();
  }, []);

  const handleWithdrawClick = (e) => {
    if (!verificationStatus) {
      e.preventDefault();
      setShowMessage(true);

      setTimeout(() => setShowMessage(false), 5000);
    }
  };

  return (
    <div className="verification-status">
      <h4 className="verification-status-heading">Verification Status</h4>
      <button
        className={`verified-button ${
          verificationStatus ? "verified" : "not-verified"
        }`}
      >
        {verificationStatus ? "Verified" : "Not Verified"}
      </button>
      <br />
      <br />
      {/* withdrawal button */}
      <div className="withdrawal-btn-container" onClick={handleWithdrawClick}>
        <Link to={verificationStatus ? "/withdrawal" : "#"}>
          <button
            className={`withdraw-button ${
              !verificationStatus ? "disabled" : ""
            }`}
          >
            Withdraw
          </button>
        </Link>
      </div>

      {showMessage && (
        <p className="warning-message">
          You need to get verified to be able to withdraw.
        </p>
      )}
    </div>
  );
};

export default VerificationStatus;

