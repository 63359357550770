import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import TextInput from "../Components/TextInput";
import axios from "axios";
import { RevolvingDot } from "react-loader-spinner";
import "../Styles/SignUp.css";

function OTPVerification() {
  const [isAnimated, setIsAnimated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [resendTimer, setResendTimer] = useState(30); // 30 seconds cooldown
  const [canResend, setCanResend] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  // Fetch email from localStorage and redirect to signup if missing
  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    if (storedEmail) {
      setEmail(storedEmail);
    } else {
      navigate("/signup"); // Redirect to signup if email is missing
    }
  }, [navigate]);

  // Page animation
  useEffect(() => {
    setIsAnimated(true);
  }, []);

  // Timer for resend OTP cooldown
  useEffect(() => {
    if (resendTimer > 0) {
      const timer = setTimeout(() => setResendTimer(resendTimer - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setCanResend(true);
    }
  }, [resendTimer]);

  // Handle OTP verification
  const handleOTPVerification = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");

    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://api.ijeaweleinvest.com/users/verify-email/",
        { email, otp }
      );

      if (response.status === 200) {
        setIsLoading(false);
        localStorage.removeItem("email");
        navigate("/signin");
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.status === 404) {
        setErrorMessage("OTP not found. Please check your email.");
      } else if (error.response && error.response.status === 400) {
        setErrorMessage("Invalid or expired OTP.");
      } else {
        setErrorMessage("An error occurred, please try again later.");
      }
    }
  };

  // Handle Resend OTP
  const handleResendOTP = async () => {
    if (!canResend) return;

    try {
      setIsLoading(true);
      setCanResend(false);
      setResendTimer(30);

      const response = await axios.post(
        "https://api.ijeaweleinvest.com/users/forgot_password/",
        // https://api.ijeaweleinvest.com/users/forgot_password/
        { email}
      );

      if (response.status === 200) {
        setSuccessMessage("A new OTP has been sent to your email.");
      }
    } catch (error) {
      setErrorMessage("Failed to resend OTP. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="signup-container no-topnav-page">
      <div className={`left-content ${isAnimated ? "slide-in-left" : ""}`}>
        <h1 className="hello-text">Hello</h1>
        <h2 className="welcome-text">
          Almost Done! Complete Your Registration
        </h2>
      </div>

      <div className={`right-form ${isAnimated ? "slide-in-right" : ""}`}>
        <div className="form-card">
          <h2>OTP Verification</h2>
          <p>Verify Your Account: Enter OTP Sent to Your Email</p>

          <form onSubmit={handleOTPVerification}>
            <TextInput
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder="OTP"
              required
            />
            <TextInput
              label="Email"
              type="email"
              value={email}
              readOnly
              placeholder="Email"
            />

            {errorMessage && <p className="error-message">{errorMessage}</p>}
            {successMessage && (
              <p className="success-message">{successMessage}</p>
            )}

            {isLoading && (
              <RevolvingDot
                type="RevolvingDot"
                color="#00BFFF"
                height={5}
                width={5}
                timeout={3000}
              />
            )}

            <button type="submit">Continue</button>
          </form>
          
          <p className="resend-otp">
            {canResend ? (
              <button onClick={handleResendOTP} className="resend-btn">
                Resend OTP
              </button>
            ) : (
              <button className="resend-btn" disabled>
                Resend OTP in {resendTimer}s
              </button>
            )}
          </p>

          <p className="create-account-text">
            Head back to Sign In? <Link to="/signin">Click Here</Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default OTPVerification;
