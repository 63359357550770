import React, { useState, useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import Layout from "../Components/Layout";
import axios from "axios";
import AssetCard from "../Components/AssetCards";
import "../Styles/Assets.css";

const Assets = () => {
  const [assets, setAssets] = useState([]);
  const [error, setError] = useState(null);
  const [inProp, setInProp] = useState(false);

  // Refs for animations
  const titleRef = useRef(null);
  const gridRef = useRef(null);

  // Fetch assets from the backend
  useEffect(() => {
    setInProp(true);
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`, // Assuming it's a Bearer token
      },
    };

    // Make the authenticated API request
    axios
      .get("https://api.ijeaweleinvest.com/asset/", config) // Pass config as the second argument
      .then((response) => {
        setAssets(response.data); // Store fetched assets
      })
      .catch((error) => {
        console.error("Error fetching assets:", error);
        setError(error); // Store the error if there's one
      });
  }, []);

  if (error) {
    return <div>Error fetching assets: {error.message}</div>;
  }

  return (
    <Layout>
      <div className="assets-page">
        {/* Title Animation */}
        <CSSTransition
          in={inProp}
          timeout={500}
          classNames="fade"
          nodeRef={titleRef}
        >
          <h1 ref={titleRef}>Assets</h1>
        </CSSTransition>

        {/* Assets Grid */}
        <div className="assets-grid" ref={gridRef}>
          {assets.map((asset) => (
            <CSSTransition
              key={asset.id}
              timeout={1000}
              classNames="fade"
              in={inProp}
              appear
            >
              <AssetCard asset={asset} />
            </CSSTransition>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Assets;
