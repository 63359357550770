import React, { useState, useEffect } from "react";
import IdentityVerification from "./IdentityVerification";
import NextOfKin from "./NextOfKin";
import { useAuth } from "../Components/AuthContext"; // Import the Auth context
import "../Styles/KycVerification.css";

const KycVerification = () => {
  const { user } = useAuth(); // Get the current user from AuthContext
  const [activeStep, setActiveStep] = useState(1);
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState("");

  const showMessage = (text, type) => {
    setMessage(text);
    setMessageType(type);
    setTimeout(() => {
      setMessage(null);
      setMessageType("");
    }, 5000);
  };

  // Ensure the component waits for user data to be available
  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="kyc-container">
      <div className="kyc-header">
        <h4>KYC Verification</h4>
        <div className="kyc-steps">
          <button
            className={`kyc-link ${activeStep === 1 ? "active" : ""}`}
            onClick={() => setActiveStep(1)}
          >
            Identity Verification
          </button>
          <button
            className={`kyc-link ${activeStep === 2 ? "active" : ""}`}
            onClick={() => setActiveStep(2)}
          >
            Next Of Kin
          </button>
        </div>
      </div>
      {message && <div className={`kyc-message ${messageType}`}>{message}</div>}
      {activeStep === 1 && (
        <IdentityVerification
          profileId={user.user} // Use the user ID from AuthContext
          showMessage={showMessage}
        />
      )}
      {activeStep === 2 && (
        <NextOfKin
          userId={user.id} // Use the user ID from AuthContext
          showMessage={showMessage}
        />
      )}
    </div>
  );
};

export default KycVerification;
